import '../App.css';
import React, { Component } from 'react';
import Background from './Background';

/**
 * Main App component that you see on the main page.
 * @typedef {object} Props
 * @extends {Component<Props>}
 * @return {React.Component}
 */
class NotFoundPage extends Component {
  playstoreLink = 'https://play.google.com/store/apps/details?id=merge.clash';
  appstoreLink = 'https://apps.apple.com/us/app/merge-clash/id1506854108';
  wikiLink = 'https://mergeclash.fandom.com/wiki/MergeClash_Wiki';
  discordLink = 'https://discord.gg/XMQSXWWDGC';

  /**
   * Initializes the images used in the Play page
   * @param {*} props - No props are used
   */
  constructor(props) {
    super(props);

    this.playstoreImage = <img src="/images/Playstore.webp" alt="Play Store"
      style={{ width: '33%', margin: '1em' }} />;
    this.appstoreImage = <img src="/images/Appstore.webp" alt="App Store"
      style={{ width: '33%', margin: '1em' }} />;
    this.wikiImage = <img src="/images/Wiki.webp" alt="Wiki"
      style={{ width: '33%', margin: '1em' }} />;
    this.discordImage = <img src="/images/Discord.webp" alt="Discord"
      style={{ width: '33%', margin: '1em' }} />;
  }

  /**
   * @return {React.Component} Component that gives links to the Playstore, Appstore, Wiki,
   *  Discord or Promo Code page if that's what the user is looking for
   */
  render() {
    return (
      <div>
        <Background />
        <div className="App"
          id="App"
        >
          <header className="App-header">
            Play Merge Clash
          </header>

          {/* Download Links */}
          <br style={{ marginTop: '1em' }} />
          <h2 className='header' style={{ marginBottom: '0em' }}>
            Download Links
          </h2>
          <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
            <figure style={{ width: 'intrinsic' }}>
              <a href={this.playstoreLink} target="_blank" rel="noopener noreferrer">
                {this.playstoreImage}
              </a>
              <a href={this.appstoreLink} target="_blank" rel="noopener noreferrer">
                {this.appstoreImage}
              </a>
            </figure>
          </div>

          <br style={{ marginTop: '1em' }} />
          <h2 className='header' style={{ marginBottom: '0em' }}>
            Looking to redeem a Promo Code? <a href="/">Click here!</a>
          </h2>

          <br style={{ marginTop: '1em' }} />
          {/* Social Links */}
          <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
            <figure style={{ width: 'intrinsic' }}>
              <a href={this.wikiLink} target="_blank" rel="noopener noreferrer">
                {this.wikiImage}
              </a>
              <a href={this.discordLink} target="_blank" rel="noopener noreferrer">
                {this.discordImage}
              </a>
            </figure>
          </div>

        </div>

      </div>
    );
  }
}

export default NotFoundPage;
