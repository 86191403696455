import {initializeApp, deleteApp} from 'firebase/app';

const promoCodeDatabaseConfig = {
  apiKey: 'AIzaSyAYYQ7Xe463YRZfEJIvMgep0axcmR5MbWk',
  authDomain: 'merge-clash.firebaseapp.com',
  databaseURL: 'https://merge-clash-promos.firebaseio.com',
  projectId: 'merge-clash',
  storageBucket: 'merge-clash.appspot.com',
  messagingSenderId: '629287617412',
  appId: '1:629287617412:web:35f6c9deaf579243ebd3d1',
  measurementId: 'G-PETBGMKKLJ',
};

let promoCodeApp = null;

// Export the created app
export const getPromoCodeApp = () => promoCodeApp;

// Initialize Firebases
export const initializeFirebase = () => {
  promoCodeApp = initializeApp(promoCodeDatabaseConfig, 'promoCode');
  console.log('Firebase initialized - app: ', promoCodeApp);
};

// Terminate Firebase
export const terminateFirebase = () => {
  return deleteApp(promoCodeApp);
};
