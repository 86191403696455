
import React, { Component } from 'react';
/**
 * Component for describing how the website works.
 * @typedef {object} Props
 * @extends {Component<Props>}
 * @return {React.Component}
 */
class HowItWorks extends Component {
  /**
   * Initializes the state of the component
   * @param {*} props - No props are used
   */
  constructor(props) {
    super(props);
  }

  /**
   * @return {React.Component} The component to describe how the website works
   */
  render() {
    return (
      <div>
        <h3 className='header'>
          How This Works
        </h3>
        <ul style={{ listStyleType: 'none' }}>
          <li>Enter your name, the promo code you want to redeem,
            then hit submit!</li>
          <li>Re-launch the Merge Clash app to see changes.</li>
        </ul>
        <h3 className='header'>
          {'It\'s not working!'}
        </h3>
        <h3 className='header'>
          {'I need to contact support!'}
        </h3>
        <ul style={{ listStyleType: 'none' }}>
          <li>Contact Support from within the Settings panel of the App</li>
        </ul>
      </div>
    );
  }
}

export default HowItWorks;
