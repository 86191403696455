import { get, getDatabase, ref, child, set } from 'firebase/database';
import { getPromoCodeApp } from './firebase';

/**
 * Gets the remaining claims for a promo code
 *
 * @example
 *   assertPromoIsValid("badPromoCodeUsedForTesting").then((snapshot) => {
 *     expect(snapshot.val()).toBeGreaterThan(10000);
 *   });
 *
 * @param {string} promo - Promo code
 * @return {Promise} Promise that resolves to a snapshot of the remaining count,
 *    if it's valid, otherwise rejects with one of the following errors:
 *    - "Promo Code Does not Exist!" if the promo code doesn't exist in the database
 *    - "Promo code has expired!" if the promo code has expired
 *    - "Promo code has no more claims!" if the promo code has no more claims
 */
export const assertPromoIsValid = (promo) => {
  const db = getDatabase(getPromoCodeApp());

  return new Promise((resolve, reject) => {
    get(child(ref(db), `/promo_prod/${promo}`)) // Todo - Catch this
        .then((snapshot) => {
          if (snapshot.val() === null) {
            reject(new Error('Promo Code Does not Exist!'));
            return;
          }
          const promo = snapshot.val();
          const currCSharpTicks = BigInt(new Date().getTime()) * 10000n + 621355968000000000n;

          if (promo.metadata.valid_until < currCSharpTicks) {
            reject(new Error('Promo code has expired!'));
            return;
          }

          if (promo.metadata.max_claim === 0) {
            reject(new Error('Promo code has no more claims!'));
            return;
          }

          resolve(promo.metadata.max_claim);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
  });
};

/**
 * Add a new promo code to a player
 *
 * @param {string} name - Player's name
 * @param {string} promo - The Promo code
 * @return {Promise} Promise that resolves when the promo is added to the
 *  database
 */
export const addPromoToPlayer = (name, promo) => {
  // Find the number of remaining claims for the promo
  return assertPromoIsValid(promo).then((remainingClaims) => {
    // Remove 1 from max_claim
    set(child(ref(getDatabase(getPromoCodeApp())), `/promo_prod/${promo}/metadata/max_claim`),
        remainingClaims - 1,
    ).then(() => {
      return set(ref(getDatabase(getPromoCodeApp()), `/pending_claims/${name}/`), promo);
    });
  });
};

/**
 * Get the promo code for a player
 *
 * @param {string} name - Player's name
 * @return {Promise} Promise that resolves to the promo code, if it exists, otherwise an empty string
 */
export const getPromoCodeForPlayer = (name) => {
  return get(child(ref(getDatabase(getPromoCodeApp())), `/pending_claims/${name}`)).then((snapshot) => {
    return snapshot.val();
  });
};
