
import '../App.css';
import React, { Component } from 'react';

/**
 * Main App component that you see on the main page.
 * @typedef {object} Props
 * @extends {Component<Props>}
 * @return {React.Component}
 */
class Instructions extends Component {
  /**
   * Initializes the state of the component
   * @param {*} props - No props are used
   */
  constructor(props) {
    super(props);

    this.image1 = <img src="/images/step1.webp" alt="How to Redeem step 1" style={{ width: '33%', margin: '0.5em' }} />;
    this.image2 = <img src="/images/step2.webp" alt="How to Redeem step 2" style={{ width: '33%', margin: '0.5em' }} />;
  }

  /**
   * @return {React.Component} Two side-by-side images scaled so they don't take up much of the screen
   */
  render() {
    return (
      <div>
        <h3 className='header' style={{ marginBottom: '0em' }}>
          Redeem in App Below!
        </h3>
        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
          <figure style={{ width: 'intrinsic' }}>
            {this.image1}
            {this.image2}
          </figure>
        </div>
      </div>
    );
  }
}

export default Instructions;
