import React from 'react';
import Router from './Router';

/**
 * Base component that includes a router to switch between pages.
 * @typedef {object} Props
 * @extends {Component<Props>}
 * @return {React.Component}
 */
function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
