import React, { Component } from 'react';
import { addPromoToPlayer } from '../database';

/**
 * Form for submitting the winner and loser of a game.
 * @typedef {object} Props
 * @extends {Component<Props>}
 * @return {React.Component}
 */
class RedeemCode extends Component {
  /**
   * Initializes the state of the component
   * @param {*} props - No props are used
   */
  constructor(props) {
    super(props);
    this.state = {
      nickname: '',
      promoCode: '',
      status: 'Ready to enter a promo code!',
    };
    this.handleNicknameChange = this.handleNicknameChange.bind(this);
    this.handlePromoCodeChange = this.handlePromoCodeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * @event onChange - Updates the state of nickname when the input changes
   * @param {event} event - The event that triggered the change
   */
  handleNicknameChange(event) {
    this.setState({ nickname: event.target.value });
  }

  /**
   * @event onChange - Updates the state of promoCode when the input changes
   * @param {event} event - The event that triggered the change
   */
  handlePromoCodeChange(event) {
    this.setState({ promoCode: event.target.value });
  }

  /**
   * @event onSubmit - Submits the winner and loser of a game to the database
   *  and provides feedback to the user
   * @param {event} event - The event that triggered the submit
   */
  handleSubmit(event) {
    event.preventDefault();
    const cleanNick = this.state.nickname.trim().toLowerCase();
    this.setState({ status: `Redeeming ${this.state.promoCode.toLowerCase()} to ${cleanNick}..` });
    addPromoToPlayer(cleanNick, this.state.promoCode.toLowerCase()).then(() => {
      let successMessage = `Successfully redeemed "${this.state.promoCode.toLowerCase()}" for ${cleanNick}!`;
      successMessage += '\n\nOpen the Gifts tab in the settings dropdown to claim your reward!';
      this.setState({ status: successMessage });
    }).catch((error) => {
      this.setState({ status: error.message });
    });
  }


  /**
   * @return {React.Component} The form for submitting the winner and loser
   *  of a game
   */
  render() {
    return (
      <form onSubmit={this.handleSubmit} autoComplete="on" >
        <label>
          Promo Code:{' '}
          <input
            type="text"
            value={this.state.promoCode}
            onChange={this.handlePromoCodeChange}
            placeholder="Promo Code!"
          />
        </label>
        <br />
        <label>
          Merge Clash Name:{' '}
          <input
            type="text"
            autoComplete="on"
            value={this.state.nickname}
            onChange={this.handleNicknameChange}
            placeholder="Your Merge Clash name"
          />
        </label>
        <br />
        <input type="submit" value="Submit" />
        <br />
        <br />
        Status:
        <br />
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {this.state.status}
        </div>
      </form>
    );
  }
}

export default RedeemCode;
