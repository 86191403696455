import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PromoPage from './PromoPage';
import NotFoundPage from './NotFoundPage.js';
import Play from './Play.js';

const Router = () => {
  return (
    <Routes> {/* The Routes decides which component to show based on the current URL.*/}
      <Route path='*' element={<NotFoundPage />} />
      <Route path='/' element={<PromoPage/>}></Route>
      <Route path='/play' element={<Play/>}></Route>
    </Routes>
  );
};

export default Router;
