import '../App.css';
import { initializeFirebase } from '../firebase';
import RedeemCode from './RedeemCode.js';
import Instructions from './Instructions.js';
import React from 'react';
import HowItWorks from './HowItWorks';
import Background from './Background';

/**
 * Main App component that you see on the main page.
 * @typedef {object} Props
 * @extends {Component<Props>}
 * @return {React.Component}
 */
function PromoPage() {
  initializeFirebase();

  return (
    <div>
      <Background />
      <div className="App"
        id="App"
      >
        <header className="App-header">
          Merge Clash Promo Codes
        </header>
        <br style={{ marginTop: '1em' }} />
        <RedeemCode />
        <br style={{ marginTop: '1em' }} />
        <Instructions />
        <HowItWorks />
      </div>

    </div>
  );
}

export default PromoPage;
