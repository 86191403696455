import '../App.css';
import React from 'react';
import Background from './Background';

/**
 * Main App component that you see on the main page.
 * @typedef {object} Props
 * @extends {Component<Props>}
 * @return {React.Component}
 */
function NotFoundPage() {
  return (
    <div>
      <Background />
      <div className="App"
        id="App"
      >
        <header className="App-header">
          Not Found!
        </header>
        <br style={{ marginTop: '1em' }} />
        <br style={{ marginTop: '1em' }} />
      </div>

    </div>
  );
}

export default NotFoundPage;
